import React from 'react';
import '../../styles/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  const productLinks = {
    'Cormix H2O Stop Liquid TDS': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/TDS-EN-Cormix%20H2O%20Stop_rev%20026-Nov%2019.pdf.pdf?alt=media&token=c999848f-5d72-40d1-add7-fd5c6fbd5521",
    'Contite Seal ACCI TDS': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Contite%20Seal%20ACCI%20TDS.pdf?alt=media&token=57543b7e-1cfb-4a0c-8378-cced787a7d76",
    'Conplug TDS': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Conplug%20TDS.pdf?alt=media&token=fa20ede8-fefe-4029-b0f8-437e92eb51a9",
    'Contite G600 TDS': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Contite%20G600%20TDS.pdf?alt=media&token=f622d6f7-f6d3-43fb-896b-6b02c7d65491"
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h2>QUICK LINKS</h2>
          <ul>
          <li><a href="https://www.cormix.com/" target="_blank" rel="noopener noreferrer">About Cormix</a></li>
            <li><Link to="/certification">Certification</Link></li>
            <li><Link to="/products">Our Products</Link></li>
            <li><Link to="/services">Our Services</Link></li>
            <li><Link to="/services/foundation-waterproofing#few-case-studies">Our Projects</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h2>DOCUMENT DOWNLOAD</h2>
          <ul>
            {Object.keys(productLinks).map((product) => (
              <li key={product}>
                <a href={productLinks[product]} target="_blank" rel="noopener noreferrer">{product}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-column contact-column">
          <h2>CONTACT US</h2>
          <ul className="contact-details">
            <li>Galaxy Specialised Waterproofing CO. LLC</li>
            <li>
              Phone & WhatsApp: 
              <a href="https://wa.me/971507352571" target="_blank" rel="noopener noreferrer" className="contact-link">
                +971 50 735 2571
              </a>
            </li>
            <li>Email: <a href="mailto:akul@galaxysp.net">akul@galaxysp.net</a></li>
            <li>Project Office: Office No. 604, D2 Tower, Al Barsha 1, Behind Mall of the Emirates, Dubai</li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        &copy; {new Date().getFullYear()} Galaxy Specialised Waterproofing CO. LLC. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
