import React, { useState } from 'react';
import '../../styles/contact.css';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      name: e.target[0].value,
      contactNumber: e.target[1].value,
      email: e.target[2].value,
      message: e.target[3].value
    };
  
    try {
      const response = await fetch('http://localhost:3001/contact', { // Ensure port is correct
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        setFormSubmitted(true);
      } else {
        console.log('Failed to send message');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  
  

  return (
    <div className="contact-us-container">
      <Helmet>
        <title>Contact Us | Galaxy Specialised Waterproofing</title>
        <meta name="description" content="Get in touch with Galaxy Specialised Waterproofing for waterproofing solutions. Contact us via email, phone, or visit our office in Dubai. We offer personalized quotes and fast response times." />
        <meta name="keywords" content="contact us, waterproofing solutions, contact Galaxy Specialised Waterproofing, Dubai waterproofing, waterproofing services, contact form" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className="contact-us-banner">
        <div className="banner-content">
          <h1>GET IN TOUCH</h1>
          <p>
            We value our clients and would be delighted to have you as one. Please reach out to us for a personalized quote or any inquiries about our services. Rest assured, we will respond promptly to your message.
          </p>
        </div>
      </div>
      <div className="contact-us-content">
        <div className="contact-info">
          <div className="info-card">
            <div className="icon">
              <i className="fas fa-envelope"></i>
            </div>
            <div className="info-details">
              <h3>Email</h3>
              <p><a href="mailto:info@galaxysp.net" className="contact-link"><strong>akul@galaxysp.net</strong></a></p>
            </div>
          </div>
          <div className="info-card">
            <div className="icon">
              <i className="fab fa-whatsapp"></i>
            </div>
            <div className="info-details">
              <h3>Phone & WhatsApp</h3>
              <p>
                <a href="https://wa.me/971507352571" target="_blank" rel="noopener noreferrer" className="contact-link"><strong>+971 50 735 2571</strong></a>
              </p>
            </div>
          </div>
          <div className="info-card">
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <div className="info-details">
              <h3>Project Office</h3>
              <p><strong>Office No. 604, D2 Tower, Al Barsha 1, Behind Mall of the Emirates, Dubai</strong></p>
            </div>
          </div>
        </div>
        <div className="contact-form">
          {formSubmitted ? (
            <div className="confirmation-message">
              <h3>Thank you!</h3>
              <p>Your message has been received. We will get back to you shortly.</p>
            </div>
          ) : (
            <>
              <h2 style={{ color: 'black' }}>Send Us a Message</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Enter your name</label>
                  <input type="text" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <label>Enter your contact number</label>
                  <input type="tel" placeholder="Your Contact Number" required />
                </div>
                <div className="form-group">
                  <label>Enter your email address</label>
                  <input type="email" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <label>Enter your message</label>
                  <textarea placeholder="Your Message" required></textarea>
                </div>
                <button type="submit" className="submit-button">Send A Message</button>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="map-section">
        <iframe
          title="Google Maps Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.9538899535!2d55.27961981500173!3d25.223081083883516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433d05b6a70b%3A0x9e8e8b72b5e6f56e!2sD2%20Tower%2C%20Al%20Barsha%201%2C%20Dubai!5e0!3m2!1sen!2sae!4v1633072340505!5m2!1sen!2sae"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
