import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; 
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Components/layout/Navbar';
import About from './Components/pages/about-galaxy-crystalline-waterproofing';
import Products from './Components/pages/crystalline-waterproofing-products-dubai-liquid-admixture';
import ContactUs from './Components/pages/contact';
import Services from './Components/pages/crystalline-waterproofing-services-dubai-leakage-repair';
import CardComponent from './Components/components/CardComponent';
import Certification from './Components/pages/cormix-uae-certification';
import Footer from './Components/layout/Footer';
import projectsImage from './Assets/Images/LogoImages/palm-projects-basement-waterproofing-leakage-repairs.webp';
import certificationImage from './Assets/Images/LogoImages/cormix-distributors-galaxy-dubai-uae.webp';
import productsImage from './Assets/Images/ProductImages/cormix-h2o-stop-liquid.webp';
import AboutUsImage from './Assets/Images/CaseStudyImages/binghatti-basement-waterproofing-with-25-years-warranty.webp';
import Features from './Components/components/Features';
import clients from './Components/components/ClientLogos';
import FoundationWaterproofing from './Components/pages/concrete-substructure-waterproofing-basement-solutions';
import ConcreteLeakageRepair from './Components/pages/concrete-leakage-repair-waterproofing';
import IntegrationIcon from './Assets/Icons/mixing-liquid-crystalline-admixture-with-concrete.webp';
import JointIcon from './Assets/Icons/contite-seal-acci-construction-joint-galaxy-dubai.webp';
import PouringIcon from './Assets/Icons/pour-waterproofed-crystallised-concrete-on-site.webp';
import './styles/App.css';

const WelcomeText = () => {
  return (
    <div>
      <h1>Galaxy Specialised Waterproofing</h1>
      <p>Crystalline Waterproofing with 25 Years Warranty</p>
      <button onClick={() => window.location.href = '/contact'} className="contact-us-button special-effect">Contact Us</button>
    </div>
  );
};

const ClientsSlider = () => {
  const [visibleClients, setVisibleClients] = useState(clients.length);
  const [expanded, setExpanded] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Function to handle screen width changes
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set to true for mobile screens (width 768px or less)
  };

  useEffect(() => {
    handleResize(); // Set initial value based on screen size
    window.addEventListener('resize', handleResize); // Update on window resize
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const heading = 'Trusted by Leading Clients and Consultants';
  
  const handleSeeAllClick = () => {
    if (expanded) {
      setVisibleClients(8); // Reduce to 8 clients if "See Less" is clicked
    } else {
      setVisibleClients(clients.length); // Show all clients if "See More" is clicked
    }
    setExpanded(!expanded); // Toggle the state
  };

  // Conditionally render this component only on non-mobile screens
  if (isMobile) {
    return null; // Don't display anything on mobile
  }

  return (
    <div className="clients-slider-container">
      <h2 className="clients-slider-heading">{heading}</h2>
      <div className="clients-slider">
        {clients.slice(0, visibleClients).map((client, index) => (
          <div key={index} className="client-logo">
            <img src={client.src} alt={client.alt} />
          </div>
        ))}
      </div>
      <button onClick={handleSeeAllClick} className="see-all-button">
        {expanded ? 'See Less' : 'See More'}
      </button>
    </div>
  );
};

function App() {
  const cardData = [
    {
      heading: 'Projects',
      description: 'Download our latest projects.',
      image: projectsImage,
      link: '/services/foundation-waterproofing#few-case-studies'
    },
    {
      heading: 'Products',
      description: 'Browse through our products.',
      image: productsImage,
      link: '/products'
    },
    {
      heading: 'Certification',
      description: 'Learn about our certifications and quality standards.',
      image: certificationImage,
      link: '/certification'
    }
  ];
  
  const handleReadMoreClick = () => {
    window.location.href = '/about';
  };

  return (
    <Router>
      <div className="container">
        <Navbar />
        <ConditionalImage />
        <Routes>
          <Route path="/" element={<Home cardData={cardData} handleReadMoreClick={handleReadMoreClick} />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/foundation-waterproofing" element={<FoundationWaterproofing />} /> 
          <Route path="/services/leakage-repair" element={<ConcreteLeakageRepair />} />
          <Route path="/certification" element={<Certification />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

const ConditionalImage = () => {
  const location = useLocation();
  return location.pathname === '/' ? (
    <div className="fullscreen-image">
      <div className="overlay">
        <WelcomeText />
      </div>
    </div>
  ) : null;
};

const Home = ({ cardData, handleReadMoreClick }) => {
  const firstThreeCards = cardData.slice(0, 3);
  const [isMobile, setIsMobile] = useState(false);

  // Function to handle screen width changes
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set to true for mobile screens (width 768px or less)
  };

  useEffect(() => {
    handleResize(); // Set initial value based on screen size
    window.addEventListener('resize', handleResize); // Update on window resize
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home-content">
      <Helmet>
        <title>Galaxy Specialised Waterproofing | 25 Years Warranty</title>
        <meta name="description" content="Galaxy Specialised Waterproofing offers expert crystalline waterproofing solutions with a 25-year warranty. Our services include foundation waterproofing, concrete leakage repair, and more." />
        <meta name="keywords" content="waterproofing, crystalline waterproofing, foundation waterproofing, concrete leakage repair, Dubai, 25 years warranty" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <Features />
      <div className="process-section">
        <div className="process-container">
          <h2 className="section-title">Our Signature Waterproofing Process</h2>
          <div className="process-step">
            <div className="process-step-header">
              <img src={IntegrationIcon} alt="Integration" className="process-icon" />
              <h3>1. Admixture Integration at the Batching Plant</h3>
            </div>
            <p>We mix our premium admixture, Cormix H2O Stop liquid, with the concrete at the batching plant, ensuring consistent waterproofing throughout.</p>
          </div>
          <div className="process-step">
            <div className="process-step-header">
              <img src={JointIcon} alt="Joint" className="process-icon" />
              <h3>2. Delivery & Pouring on Site</h3>
            </div>
            <p>Waterproofed concrete is delivered to the site. The concrete is poured directly into structures, eliminating traditional waterproofing methods, saving 30-40 days of construction time.</p>
          </div>
          <div className="process-step">
            <div className="process-step-header">
              <img src={PouringIcon} alt="Pouring" className="process-icon" />
              <h3>3. Construction Joint Treatment</h3>
            </div>
            <p>Construction joints are treated with Contite Seal ACCI slurry, and swellable bars are added for extra protection.</p>
          </div>
        </div>
      </div>

      <div className="card-container">
        {firstThreeCards.map((data, index) => (
          <CardComponent key={index} cardData={data} />
        ))}
      </div>

      {/* Conditionally render the About Us section based on screen size */}
      {!isMobile && (
        <div className="about-us-section">
          <div className="about-us-image">
            <img src={AboutUsImage} alt="About Us" />
          </div>
          <div className="about-us-content">
            <h2 className="about-title">About Us</h2>
            <p className="about-description">
              Galaxy Specialised Waterproofing CO LLC is a fast-growing company specializing in concrete waterproofing solutions for commercial and residential buildings, villas, water tanks, lift pits, swimming pools, and other concrete structures. We provide professional services for waterproofing of sub-structure in fresh projects, leakage repair, complete with product warranty and service support.
            </p>
            <button onClick={handleReadMoreClick} className="read-more-button">Read More</button>
          </div>
        </div>
      )}

      <div className="clients-section">
        <div className="clients-container">
          <ClientsSlider />
        </div>
      </div>
    </div>
  );
};

export default App;
