import React from 'react';
import CardComponent from '../components/CardComponent';
import '../../styles/about-galaxy-crystalline-waterproofing.css';
import AboutUsImage from '../../Assets/Images/CaseStudyImages/binghatti-basement-waterproofing-with-25-years-warranty.webp';
import CormixImage from '../../Assets/Images/ProductImages/cormix-h2o-stop-liquid.webp';
import CertificationImage from '../../Assets/Images/LogoImages/cormix-distributors-galaxy-dubai-uae.webp';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div className="about-container">
      <Helmet>
        <title>About Us | Galaxy Specialised Waterproofing</title>
        <meta name="description" content="Learn more about Galaxy Specialised Waterproofing CO LLC, a fast-growing waterproofing company in Dubai specializing in concrete waterproofing solutions for commercial and residential buildings." />
        <meta name="keywords" content="waterproofing, concrete waterproofing, waterproofing solutions, UAE waterproofing, DEWA projects, leakage repair, Kapil Arora" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className="about-content">
        <div className="about-text">
          <h1>About Us</h1>
          <p>
  Galaxy Specialised Waterproofing CO LLC is a fast growing company specialising in concrete waterproofing solutions for commercial and residential buildings, villas, water tanks, lift pits, swimming pools, and other concrete structures.
  We provide professional services for waterproofing of sub-structure in fresh projects, leakage repair, complete with product warranty and service support.
  We are authorized distributors and applicators in the UAE for a British-owned company 
  <a href="https://www.cormix.com/" target="_blank" rel="noopener noreferrer" className="cormix-link">
     <strong> <u>CORMIX International LIMITED</u> </strong>
  </a> 
  based in Thailand for high-quality international standard DCL certified Crystalline Integral Admixtures and repair systems.
</p>
          <p>
            The company has established a strong and impressive customer base in a relatively short span of time under its Managing Director Kapil Arora, a well-known waterproofing expert in the UAE.
            With more than 25 years of expertise in admixture technology and surface-applied waterproofing solutions, Kapil has led many successful projects in the region.
            Our highly committed team of experts has successfully plugged leaks in some of the toughest and extremely challenging cases in the UAE.
          </p>
          <p>
            We handle all kinds of waterproofing cases giving the customer the most comprehensive solutions in the most cost-effective way.
            Our motto is reliability and commitment to offer tried and tested solutions that are most suited to a case.
            We are proficient in all kinds of leakage repair and maintenance works (supply and apply) based on Crystalline Technology and have successfully executed both government and private sector repair cases.
          </p>
          <p>
            We have an excellent track record with a diverse database of reputed clients and approved government sub-contractors in the UAE.
            DEWA is one of our long-standing clients and has been coming back to us for its waterproofing needs.
            We have successfully completed leakage repair works for several DEWA chambers at different locations in Dubai with more coming up in the following months.
          </p>
          <p>
            We offer tailor-made solutions for each repair need and are also equipped to handle turnkey projects as per the requirement and specifications of each project.
            We are committed to providing complete peace of mind to clients and after-sales support where needed.
          </p>
        </div>

        <div className="about-cards">
          <CardComponent
            cardData={{
              heading: 'Our Services',
              description: 'Learn more about the services we provide for waterproofing and leakage repair.',
              image: AboutUsImage,
              link: '/services',
            }}
          />
          <CardComponent
            cardData={{
              heading: 'View Our Products',
              description: 'Discover our range of high-quality waterproofing products.',
              image: CormixImage,
              link: '/products',
            }}
          />
          <CardComponent
            cardData={{
              heading: 'View Our Certification',
              description: 'Explore our success stories and recent projects in detail.',
              image: CertificationImage,
              link: '/certification',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default About;
