import React from 'react';
import '../../styles/CardComponent.css';

function CardComponent({ cardData }) {
  const { image, heading, description, link, downloadLink } = cardData;

  const handleClick = () => {
    if (link) {
      window.location.href = link; // Navigate to the specified route
    } else if (downloadLink) {
      window.open(downloadLink, '_blank'); // Initiate file download
    }
  };

  return (
    <div className="card" onClick={handleClick}>
      <div className="card-content">
        <img
          src={image}
          alt="Card"
          className="card-image"
          style={
            heading === 'Our Services' || heading === 'View Our Certification'
              ? { objectFit: 'cover' }
              : {}
          }
        />
        <h3 className="card-heading">{heading}</h3>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
}

export default CardComponent;
