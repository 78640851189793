import React from 'react';
import CardComponent from '../components/CardComponent';
import '../../styles/crystalline-waterproofing-services-dubai-leakage-repair.css';
import '../../Assets/Images/ServicesImages/repair-leakage-with-crystalline-solutions.webp'
import '../../Assets/Images/ServicesImages/crystalline-waterproofing-galaxy-dubai-uae.webp'
import { Helmet } from 'react-helmet';
function Services() {
  return (
    <div className="services-container">
      <Helmet>
  <title>Waterproofing Services | Galaxy Specialised Waterproofing</title>
  <meta name="description" content="Discover expert waterproofing services by Galaxy Specialised Waterproofing, including concrete substructure waterproofing and concrete leakage repair. Protect your structures with our cutting-edge solutions." />
  <meta name="keywords" content="waterproofing services, concrete waterproofing, substructure waterproofing, leakage repair, crystalline waterproofing, concrete protection" />
  <meta name="robots" content="index,follow" />
</Helmet>
      <h1>Expert Waterproofing Services Designed to Protect Your Structures</h1>
      <div className="services-content">
        <CardComponent
          cardData={{
            heading: 'Concrete Substructure Waterproofing',
            description: 'Experience robust waterproofing for foundations with our expert solutions. Click to see related projects.',
            image: require('../../Assets/Images/ServicesImages/crystalline-waterproofing-galaxy-dubai-uae.webp'),
            link: '/services/foundation-waterproofing', // Link to Foundation Waterproofing page
          }}
        />
        <CardComponent
          cardData={{
            heading: 'Concrete Leakage Repair',
            description: 'Prevent and repair leakage effectively with our cutting-edge crystalline waterproofing solutions.',
            image: require('../../Assets/Images/ServicesImages/repair-leakage-with-crystalline-solutions.webp'),
            link: '/services/leakage-repair', // Link to Concrete Leakage Repair page
          }}
        />
      </div>
      <button className="contact-button" onClick={() => window.location.href = '/contact'}>Contact Us</button>
    </div>
  );
}

export default Services;
