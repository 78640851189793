import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import galaxyLogo from '../../Assets/Images/LogoImages/galaxy-specialised-waterproofing.webp'; // Galaxy logo
import cormixLogo from '../../Assets/Images/LogoImages/cormix-galaxy-waterproofing-dubai-uae.webp'; // Cormix logo
import WaterproofingIcon from '../../Assets/Icons/cormix-h2o-stop-mixed-with-concrete.webp';
import LeakageIcon from '../../Assets/Icons/leakage-repair-with-crystalline-waterproofing-solutions.webp';
import '../../styles/Navbar.css';

const Navbar = () => {
  // State for mobile menu
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const productLinks = {
    'Cormix H2O Stop': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/TDS-EN-Cormix%20H2O%20Stop_rev%20026-Nov%2019.pdf.pdf?alt=media&token=c999848f-5d72-40d1-add7-fd5c6fbd5521",
    'Contite Seal ACCI': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Contite%20Seal%20ACCI%20TDS.pdf?alt=media&token=57543b7e-1cfb-4a0c-8378-cced787a7d76",
    'Conplug F': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Conplug%20TDS.pdf?alt=media&token=fa20ede8-fefe-4029-b0f8-437e92eb51a9",
    'Contite G600': "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/TDS-EN-Cormix%20H2O%20Stop_rev%20026-Nov%2019.pdf.pdf?alt=media&token=c999848f-5d72-40d1-add7-fd5c6fbd5521"
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo-container">
          <img src={galaxyLogo} alt="Galaxy Logo" className="logo" />
          <a href="https://www.cormix.com/" target="_blank" rel="noopener noreferrer">
            <img src={cormixLogo} alt="Cormix Logo" className="logo cormix-logo" />
          </a>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuVisible && (
          <div className="mobile-menu">
            <NavLink exact to="/" activeClassName="active" onClick={toggleMobileMenu}>Home</NavLink>
            <NavLink to="/about" activeClassName="active" onClick={toggleMobileMenu}>About</NavLink>
            <NavLink to="/services" activeClassName="active" onClick={toggleMobileMenu}>Services</NavLink>
            <NavLink to="/products" activeClassName="active" onClick={toggleMobileMenu}>Products</NavLink>
            <NavLink to="/contact" activeClassName="active" onClick={toggleMobileMenu}>Contact Us</NavLink>
          </div>
        )}

        {/* Desktop Links */}
        <div className="nav-links">
          <NavLink exact to="/" activeClassName="active">Home</NavLink>
          <NavLink to="/about" activeClassName="active">About</NavLink>

          {/* Services Dropdown */}
          <div
            className="services-dropdown"
            onMouseEnter={() => setIsMobileMenuVisible(false)}
            onMouseLeave={() => setIsMobileMenuVisible(false)}
          >
            <NavLink to="/services" activeClassName="active">Services <span className="arrow">▼</span></NavLink>
            <div className="dropdown-menu">
              <NavLink to="/services/foundation-waterproofing">
                <img src={WaterproofingIcon} alt="Waterproofing Icon" className="nav-icon" /> Concrete Substructure Waterproofing
              </NavLink>
              <NavLink to="/services/leakage-repair">
                <img src={LeakageIcon} alt="Leakage Icon" className="nav-icon" /> Concrete Leakage Repair
              </NavLink>
            </div>
          </div>

          {/* Products Dropdown */}
          <div
            className="products-dropdown"
            onMouseEnter={() => setIsMobileMenuVisible(false)}
            onMouseLeave={() => setIsMobileMenuVisible(false)}
          >
            <NavLink to="/products" activeClassName="active">Products <span className="arrow">▼</span></NavLink>
            <div className="dropdown-menu">
              {Object.keys(productLinks).map((product) => (
                <a key={product} href={productLinks[product]} download>
                  {product}
                </a>
              ))}
            </div>
          </div>
          <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
