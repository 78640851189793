import React from 'react';
import '../../styles/Features.css'
import TimeCostIcon from '../../Assets/Icons/save-30-days-with-galaxy-crystalline-waterproofing.webp';  
import WarrantyIcon from '../../Assets/Icons/25-years-warranty-galaxy-uae.webp'
import LiquidAdmixtureIcon from '../../Assets/Icons/only-liquid-crystalline-admixture-uae-25-years-warranty.webp'
import { Helmet } from 'react-helmet';

const Features = () => {
  const features = [
    {
      title: 'Time and Cost Saving',
      description: 'Experience significant time and cost savings with our efficient waterproofing solutions.',
      icon: TimeCostIcon // Use the imported icon image
    },
    {
      title: '25 Years Warranty',
      description: 'Benefit from our 25 years warranty on our waterproofing services.',
      icon: WarrantyIcon // Use the imported icon image
    },
    {
      title: 'Liquid Crystalline Admixture',
      description: 'Utilize the only liquid crystalline admixture available in the UAE, ensuring superior quality and durability.',
      icon: LiquidAdmixtureIcon // Use the imported icon image
    }
  ];

  return (
    <div className="features-section">
      <Helmet>
        <title>Why Choose Us? | Galaxy Specialised Waterproofing</title>
        <meta name="description" content="Discover the benefits of choosing Galaxy Specialised Waterproofing, including time and cost savings, 25-year warranty, and the only liquid crystalline admixture in the UAE." />
        <meta name="keywords" content="waterproofing, crystalline waterproofing, time saving waterproofing, cost saving waterproofing, 25 year warranty waterproofing, liquid crystalline admixture, UAE waterproofing solutions" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <h2>Why Choose Us?</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">
              <img src={feature.icon} alt={feature.title} width="50" height="50" /> {/* Display the icon */}
            </div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
