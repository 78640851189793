import React from 'react';
import '../../styles/crystalline-waterproofing-products-dubai-liquid-admixture.css';
import ContiteG600 from '../../Assets/Images/ProductImages/contite-g600.webp';
import Conplug from '../../Assets/Images/ProductImages/conplug.webp';
import Contite from '../../Assets/Images/ProductImages/contite-seal-acci.webp';
import Cormix from '../../Assets/Images/ProductImages/cormix-h2o-stop-liquid.webp';
import { Helmet } from 'react-helmet';

const Products = () => {
  const productData = [
    { 
      id: 1, 
      title: 'Cormix H2O Stop Liquid', 
      image: Cormix, 
      tdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/TDS-EN-Cormix%20H2O%20Stop_rev%20026-Nov%2019.pdf.pdf?alt=media&token=c999848f-5d72-40d1-add7-fd5c6fbd5521",
      msdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/CormixMSDS.pdf?alt=media&token=7a137e6d-97cd-48ac-b1ee-fe1022452870"
    },
    { 
      id: 2, 
      title: 'Contite Seal ACCI', 
      image: Contite, 
      tdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Contite%20Seal%20ACCI%20TDS.pdf?alt=media&token=57543b7e-1cfb-4a0c-8378-cced787a7d76",
      msdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/ContiteMSDS.pdf?alt=media&token=40ccbdde-be6f-4586-aa39-bffdd61b5f55"
    },
    { 
      id: 3, 
      title: 'Conplug', 
      image: Conplug, 
      tdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Conplug%20TDS.pdf?alt=media&token=fa20ede8-fefe-4029-b0f8-437e92eb51a9",
      msdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/ConplugMSDS.pdf?alt=media&token=413c7845-2a99-4882-b15b-aa20a7f8d1fd"
    },
    { 
      id: 4, 
      title: 'Contite G600', 
      image: ContiteG600, 
      tdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Contite%20G600%20TDS.pdf?alt=media&token=f622d6f7-f6d3-43fb-896b-6b02c7d65491",
      msdsLink: "https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/MSDS-EN-Contite%20G600_Nov20.pdf?alt=media&token=14e15a65-7195-490f-999b-67888af52688"
    }
  ];

  return (
    <div className="products-container">
      <Helmet>
  <title>Waterproofing Products | Galaxy Specialised Waterproofing</title>
  <meta name="description" content="Explore high-quality waterproofing solutions, including Cormix H2O Stop Liquid, Contite Seal ACCI, Conplug, and Contite G600. Download Technical and Material Safety Data Sheets for each product." />
  <meta name="keywords" content="waterproofing products, Cormix H2O Stop Liquid, Contite Seal ACCI, Conplug, Contite G600, waterproofing solutions, TDS, MSDS, concrete waterproofing" />
  <meta name="robots" content="index,follow" />
</Helmet>
      <h1 className="products-heading">Explore Our High-Quality Waterproofing Solutions</h1>
      <div className="products-grid">
        {productData.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.image} alt={product.title} />
            <h2>{product.title}</h2>
            <div className="button-container">
              <a href={product.tdsLink} target="_blank" rel="noopener noreferrer">
                <button className="tds-button">Technical Data Sheet</button>
              </a>
              <a href={product.msdsLink} target="_blank" rel="noopener noreferrer">
                <button className="msds-button">Material Safety Data Sheet</button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
