import Model from '../../Assets/Images/ClientImages/Model.webp';
import Edmac from '../../Assets/Images/ClientImages/Edmac.webp';
import Plus971 from '../../Assets/Images/ClientImages/Plus971.webp';
import SevenLines from '../../Assets/Images/ClientImages/SevenLines.webp';
import Alajmi from '../../Assets/Images/ClientImages/Alajmi.webp';
import Dewa from '../../Assets/Images/ClientImages/DEWA.webp';
import DxbDesignStudio from '../../Assets/Images/ClientImages/DXB Design Studio.webp';
import EngAdnanSaffarini from '../../Assets/Images/ClientImages/Eng Adnan Saffarini.webp';
import Inspiration from '../../Assets/Images/ClientImages/Inspiration.webp';
import Lacasa from '../../Assets/Images/ClientImages/Lacasa.webp';
import Nakheel from '../../Assets/Images/ClientImages/Nakheel.webp';
import Neb from '../../Assets/Images/ClientImages/NEB.webp';
import Qhc from '../../Assets/Images/ClientImages/QHC.webp';
import Yecs from '../../Assets/Images/ClientImages/YECS.webp';
import Icon from '../../Assets/Images/ClientImages/Icon.webp';
import Maks from '../../Assets/Images/ClientImages/Maks.webp';
import Eskan from '../../Assets/Images/ClientImages/Eskan.webp';
import Alasri from '../../Assets/Images/ClientImages/Alasri.webp';
import Noble from '../../Assets/Images/ClientImages/Noble.webp';
import Ia from '../../Assets/Images/ClientImages/ia.webp';
import Federal from '../../Assets/Images/ClientImages/Federal.webp';
import Options from '../../Assets/Images/ClientImages/Options.webp';
import ArifBintoak from '../../Assets/Images/ClientImages/Arif&Bintoak.webp';
import Atrium from '../../Assets/Images/ClientImages/Atrium.webp';
import DesignCenter from '../../Assets/Images/ClientImages/DesignCenter.webp';
import Emsquare from '../../Assets/Images/ClientImages/Emsquare.webp';
import Erga from '../../Assets/Images/ClientImages/erga.webp';
import Jouzy from '../../Assets/Images/ClientImages/Jouzy.webp';
import OrientCrown from '../../Assets/Images/ClientImages/OrientCrown.webp';
import Amec from '../../Assets/Images/ClientImages/Amec.webp';
import Datum from '../../Assets/Images/ClientImages/Datum.webp';
import ECG from '../../Assets/Images/ClientImages/ECG.webp';
import NisreenKayali from '../../Assets/Images/ClientImages/NisreenKayali.webp';
import Renaissance from '../../Assets/Images/ClientImages/Renaissance.webp';
import ADU from '../../Assets/Images/ClientImages/ADU.webp';
import AlGurg from '../../Assets/Images/ClientImages/AlGurg.webp';
import AlHashemi from '../../Assets/Images/ClientImages/AlHashemi.webp';
import Hutaib from '../../Assets/Images/ClientImages/Hutaib.webp';
import MDBhatia from '../../Assets/Images/ClientImages/MDBhatia.webp';
import SilverStone from '../../Assets/Images/ClientImages/SilverStone.webp';

const ClientLogos = [
  { src: SilverStone, alt: 'SilverStone' },
  { src: Model, alt: 'Model' },
  { src: Edmac, alt: 'Edmac' },
  { src: Alajmi, alt: 'Alajmi' },
  { src: EngAdnanSaffarini, alt: 'EngAdnanSaffarini' },
  { src: ArifBintoak, alt: 'Arif&Bintoak' },
  { src: Lacasa, alt: 'Lacasa' },
  { src: Nakheel, alt: 'Nakheel' },
  { src: Plus971, alt: 'Plus971' },
  { src: SevenLines, alt: 'SevenLines' },
  { src: Emsquare, alt: 'Emsquare' },
  { src: Atrium, alt: 'Atrium' },
  { src: DesignCenter, alt: 'DesignCenter' },
  { src: Jouzy, alt: 'Jouzy' },
  { src: Dewa, alt: 'Dewa' },
  { src: DxbDesignStudio, alt: 'DxbDesignStudio' },
  { src: Inspiration, alt: 'Inspiration' },
  { src: Neb, alt: 'Neb' },
  { src: Qhc, alt: 'Qhc' },
  { src: Yecs, alt: 'Yecs' },
  { src: Icon, alt: 'Icon' },
  { src: Maks, alt: 'Maks' },
  { src: Eskan, alt: 'Eskan' },
  { src: Alasri, alt: 'Alasri' },
  { src: Noble, alt: 'Noble' },
  { src: Ia, alt: 'Ia' },
  { src: Federal, alt: 'Federal' },
  { src: Options, alt: 'Options' },
  { src: Erga, alt: 'Erga' },
  { src: OrientCrown, alt: 'OrientCrown' },
  { src: Amec, alt: 'Amec' },
  { src: Datum, alt: 'Datum' },
  { src: ECG, alt: 'ECG' },
  { src: NisreenKayali, alt: 'NisreenKayali' },
  { src: Renaissance, alt: 'Renaissance' },
  { src: ADU, alt: 'ADU' },
  { src: AlGurg, alt: 'AlGurg' },
  { src: AlHashemi, alt: 'AlHashemi' },
  { src: Hutaib, alt: 'Hutaib' },
  { src: MDBhatia, alt: 'MDBhatia' }
];

export default ClientLogos;
