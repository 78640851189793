import React, { useEffect } from 'react';
import '../../styles/concrete-substructure-waterproofing-foundation-solutions.css';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function FoundationWaterproofing() {
  const location = useLocation();

  // Scroll to the element with id 'few-case-studies' when the page loads and the URL hash matches
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const recentProjects = [
    { 
      image: require('../../Assets/Images/CaseStudyImages/wadi-al-safa-concrete-basement-waterproofing.webp.webp'), 
      title: '2B+G+9+R', 
      subtitle: 'Wadi Al Safa 3',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/WadiAlSafa3.pdf?alt=media&token=ee97542e-c151-4b2a-b260-9f8d354f34da' 
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/furjan-residential-waterproofing-dubai.webp'), 
      title: '3 Buildings - B+G+7', 
      subtitle: 'Al Furjan',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Furjan.pdf?alt=media&token=7e1f0eff-a258-4e80-ab76-b9e1d97af669'
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/binghatti-basement-waterproofing-with-25-years-warranty.webp'),  // Image for Binghatti Headquarters
      title: 'Binghatti HQ - 4B+G+M+3', 
      subtitle: 'Sheikh Zayed Road',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Binghatti.pdf?alt=media&token=4595696c-3104-4417-8466-f482bf073f75' // Link for Binghatti Headquarters
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/deira-project-concrete-waterproofing.webp'), 
      title: 'B+G+M+1+R', 
      subtitle: 'Deira',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Deira.pdf?alt=media&token=170b8b2e-2e8e-425a-9461-fd3d07f49230' 
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/pearl-jumeirah-waterproofing-concrete-basement.webp'), 
      title: 'B+G+1', 
      subtitle: 'Pearl Jumeirah',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/PearlJum.pdf?alt=media&token=662dfe37-77d6-4c06-8615-34117e0766ed' 
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/jumeirah-bay-waterproofing-solutions.webp'), 
      title: 'B+G+1', 
      subtitle: 'Jumeirah Bay',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/JumBay%20.pdf?alt=media&token=efcb83b9-8f4f-4cc7-9d68-4bc3e735abf5' 
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/la-mer-resort-waterproofing-dubai.webp'), 
      title: ' B+G+1', 
      subtitle: 'La Mer',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/LaMer.pdf?alt=media&token=c788b79b-5d4f-4ebd-85b8-0a7597f99861' 
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/palm-jumeirah-crystalline-waterproofing.webp'), 
      title: 'B+G+1', 
      subtitle: 'Palm Jumeirah',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Palm.pdf?alt=media&token=39ae0656-d601-4653-9e6b-3ea09ca37d21'
    },
  ];

  return (
    <div className="foundation-container">
      <Helmet>
  <title>Concrete Substructure Waterproofing | Galaxy Specialised Waterproofing</title>
  <meta name="description" content="Discover expert concrete substructure waterproofing solutions by Galaxy Specialised Waterproofing. We offer crystalline technology to ensure long-term protection against water seepage and moisture damage." />
  <meta name="keywords" content="foundation waterproofing, concrete substructure waterproofing, crystalline waterproofing, Dubai waterproofing, water seepage prevention, concrete protection, construction joint treatment" />
  <meta name="robots" content="index,follow" />
</Helmet>
      <h1 className="hidden">Concrete Substructure Waterproofing</h1>
      <p className="hidden">
        Concrete Substructure Waterproofing is crucial for protecting the structural integrity of buildings by preventing water seepage and moisture damage. Our solutions are tailored to the specific requirements of each project, ensuring long-term protection and durability.
      </p>
      <p className="hidden">
        With our expertise in crystalline waterproofing technology, we provide a permanent solution that integrates with the concrete, forming a protective barrier against water.
      </p>

      <h2 className="hidden">Our Process</h2>
      <p className="hidden">
        Concrete Substructure Waterproofing process includes:
      </p>
      <ul className="hidden">
        <li>Mixing of crystalline admixture to the concrete at the batching plant</li>
        <li>Delivery and pouring of waterproofed concrete on site</li>
        <li>Treatment of construction joints with Contite Seal ACCI and swellable bar for extra protection</li>
      </ul>

      <div className="foundation-image-gallery hidden">
        <img src={require('../../Assets/Images/ServicesImages/cormix-h2o-stop-liquid-admixture.webp')} alt="Cormix H2O Stop Liquid Admixture." />
        <img src={require('../../Assets/Images/ServicesImages/liquid-admixture-mixed-with-concrete-at-plant.webp')} alt="Cormix H2O Stop Liquid admixture gets mixed with concrete at the batching plant." />
        <img src={require('../../Assets/Images/ServicesImages/waterproofed-concrete-poured-at-site.webp')} alt="The waterproofed concrete is poured directly at the site." />
      </div>
      
      <h2 id="few-case-studies" className="hidden">Few Case Studies</h2>
      <p className="hidden">
        We have successfully completed foundation waterproofing for various projects across Dubai, including commercial, residential, and industrial structures. Our solutions have been trusted by many leading developers for their effectiveness and long-term durability.
      </p>

      <div className="recent-projects-gallery hidden">
        {recentProjects.map((project, index) => (
          <div key={index} className="project-card" onClick={() => window.open(project.link, '_blank')}>
            <img src={project.image} alt={project.title} />
            <h3>{project.title}</h3>
            <p>{project.subtitle}</p>
          </div>
        ))}
      </div>

      <button className="contact-button hidden" onClick={() => window.location.href = '/contact'}>
        Contact Us for More Details
      </button>
    </div>
  );
}

export default FoundationWaterproofing;
