import React, { useEffect } from 'react';
import '../../styles/concrete-leakage-repair-waterproofing.css';
import { Helmet } from 'react-helmet';

function ConcreteLeakageRepair() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  // Updated recent projects data with download links
  const recentProjects = [
    { 
      image: require('../../Assets/Images/CaseStudyImages/shoreline-apartments-leakage-repair-dubai-uae.webp'), 
      title: 'Shoreline Apartments', 
      subtitle: 'Palm Jumeirah',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Shoreline.pdf?alt=media&token=3b13165a-2d3e-4d36-a8c8-180c4e4b7cea'
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/murano-residential-repair-leakage-dubai.webp'), 
      title: 'Murano Residences', 
      subtitle: 'Al Furjan',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/Murano.pdf?alt=media&token=249fb57c-352a-4670-84d8-36f2a11f1248'
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/jaddaf-building-leakage-repair.webp'), 
      title: 'FAB Headquarters', 
      subtitle: 'Al Jaddaf',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/FAB.pdf?alt=media&token=6ba634fb-21e6-47c2-a981-007e1d396baa'
    },
    { 
      image: require('../../Assets/Images/CaseStudyImages/dewa-repair-leakage-dubai.webp'), 
      title: 'DEWA Chambers', 
      subtitle: '',
      link: 'https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/DEWA.pdf?alt=media&token=ee233e81-0339-484c-9428-5daa0e1f1b32'
    }
  ];

  return (
    <div className="concrete-leakage-container">
      <Helmet>
        <title>Concrete Leakage Repair | Galaxy Specialised Waterproofing</title>
        <meta name="description" content="Explore expert concrete leakage repair services by Galaxy Specialised Waterproofing. We offer tailored solutions for concrete cracks, water ingress, and leakage repairs across Dubai." />
        <meta name="keywords" content="concrete leakage repair, concrete crack repair, water ingress, waterproofing solutions, Dubai waterproofing, concrete sealing, DEWA waterproofing projects" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <h1 className="hidden">Concrete Leakage Repair</h1>
      <p className="hidden">
        Concrete leakage repair is critical for restoring the structural integrity and durability of buildings where cracks and leaks have developed. Our solutions are tailored to stop water ingress and ensure long-term protection.
      </p>
      <p className="hidden">
        Our repair methods involve opening up cracks, filling them with specialized products, and sealing the surface to prevent further leakage. 
      </p>

      <h2 className="hidden">Our Process</h2>
      <p className="hidden">
        Our concrete leakage repair process includes:
      </p>
      <ul className="hidden">
        <li>Opening up the crack where the leakage is occurring</li>
        <li>Filling up the crack with Conplug for rapid sealing</li>
        <li>Applying Contite Seal ACCI slurry (brush applied) to seal the surface and prevent further leakage</li>
      </ul>

      <div className="concrete-leakage-image-gallery hidden">
        <img src={require('../../Assets/Images/ServicesImages/fast-setting-grout-conplug-to-fill-crack.webp')} alt="Concrete Leakage Repair 1" />
        <img src={require('../../Assets/Images/ServicesImages/filling-up-with-Conplug.webp')} alt="Concrete Leakage Repair 2" />
        <img src={require('../../Assets/Images/ServicesImages/coating-with-contite-seal-acci.webp')} alt="Concrete Leakage Repair 3" />
      </div>

      <h2 className="hidden">Few Case Studies</h2>
      <p className="hidden">
        We have successfully completed leakage repairs for various projects across Dubai, including underground parking, water tanks, basements, residential buildings and villas.
      </p>

      <div className="recent-projects-gallery hidden">
        {recentProjects.map((project, index) => (
          <div key={index} className="project-card" onClick={() => window.open(project.link, '_blank')}>
            <img src={project.image} alt={project.title} />
            <h3>{project.title}</h3>
            <p>{project.subtitle}</p>
          </div>
        ))}
      </div>

      <button className="contact-button hidden" onClick={() => window.location.href = '/contact'}>
        Contact Us for More Details
      </button>
    </div>
  );
}

export default ConcreteLeakageRepair;
