import React from 'react';
import { Helmet } from 'react-helmet';  // Import Helmet for meta tags
import CertificationImage from '../../Assets/Images/LogoImages/cormix-distributors-galaxy-dubai-uae.webp'; // Import the Certification image
import '../../styles/cormix-uae-certification.css'; // Import the CSS file

function Certification() {
  return (
    <div className="certification-container"> {/* Apply the container class */}
      
      {/* Helmet for adding meta tags */}
      <Helmet>
        <title>Certification | Galaxy Specialised Waterproofing</title>
        <meta name="description" content="View Galaxy Specialised Waterproofing's DCL Certificate and Distributor and Applicator Certification. Certified by the highest standards for waterproofing solutions." />
        <meta name="keywords" content="DCL certification, waterproofing certification, distributor certification, UAE waterproofing, certified waterproofing solutions" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      {/* Heading styled according to the products CSS */}
      <h1 className="certification-heading">Certification</h1>
      
      {/* Box for DCL Certificate */}
      <a href="https://firebasestorage.googleapis.com/v0/b/galaxy-ba25c.appspot.com/o/DCL.pdf?alt=media&token=1da7447c-b5c5-49ba-ac80-f99bdae02e97" 
         className="dcl-certificate-box" 
         target="_blank" 
         rel="noopener noreferrer">
        View Our DCL Certificate
      </a>

      {/* Box for viewing the certification image */}
      <a href={CertificationImage} 
         className="dcl-certificate-box" 
         target="_blank" 
         rel="noopener noreferrer">
        View Our Distributor and Applicator Certification Image
      </a>
    </div>
  );
}

export default Certification;
